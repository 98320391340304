<template>
  <div id="modifyProduct">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          autocomplete="off"
          v-model="form.code"
          name="code"
          label="编号"
          disabled
        />
        <van-field
          autocomplete="off"
          v-model="form.name"
          name="name"
          label="商品名称"
        />
        <van-field
          autocomplete="off"
          v-model="form.shortName"
          name="shortName"
          label="商品简称"
        />
        <van-cell title="商品图片" autocomplete="off" v-model="form.picture">
          <UploadImage v-model="form.picture" @change="imageChange"
        /></van-cell>
        <van-field
          autocomplete="off"
          label="外部编号(条码)"
          name="externalCode"
          required
          v-model:value="form.externalCode"
        />
        <van-cell @click="showDialog">
          <van-field
            autocomplete="off"
            v-model="form.categoryName"
            name="categoryName"
            label="所属类目名"
            required
          >
          </van-field>
        </van-cell>

        <van-cell @click="showBrandDialog">
          <van-field
            autocomplete="off"
            label="商品品牌"
            name="brandId"
            required
            v-model:value="form.brandName"
          ></van-field>
        </van-cell>
        <van-field
          autocomplete="off"
          label="规格"
          name="spec"
          v-model:value="form.spec"
          allow-clear
        >
        </van-field>

        <van-field
          autocomplete="off"
          label="单位"
          name="unit"
          v-model:value="form.unit"
          allow-clear
        />

        <van-field autocomplete="off" name="radio" label="是否称重商品">
          <template #input>
            <van-radio-group v-model="form.isWeight" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          autocomplete="off"
          v-model="form.taxRate"
          name="taxRate"
          required
          type="number"
          label="进项税率（%）"
        />
        <van-field
          autocomplete="off"
          v-model="form.saleTaxRate"
          required
          type="number"
          name="saleTaxRate"
          label="销项税率（%）"
        />
        <van-field
          autocomplete="off"
          v-model="form.purchasePrice"
          name="purchasePrice"
          required
          type="number"
          label="采购价（元）"
        />
        <van-field
          autocomplete="off"
          v-model="form.salePrice"
          required
          type="number"
          name="salePrice"
          label="销售价（元）"
        />
        <van-field
          autocomplete="off"
          v-model="form.retailPrice"
          required
          type="number"
          name="retailPrice"
          label="零售价（元）"
        />
        <van-field
          autocomplete="off"
          v-model="form.available"
          required
          name="available"
          label="状态"
          placeholder="是否启用"
        >
          <template v-slot:input>
            <van-dropdown-menu style="width: 100%">
              <van-dropdown-item
                v-model="form.available"
                :options="statusList"
              />
            </van-dropdown-menu>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <CategorySelect
      :visible="categorySelectVisible"
      @confirm="categorySelectConfirm"
    ></CategorySelect>
    <BrandSelect
      :visible="brandSelectVisible"
      @confirm="brandSelectConfirm"
    ></BrandSelect>
  </div>
</template>
<script>
import { watch } from "less";
import { get, update } from "../../service/api/product";
import { getCamelCharsUpperCase, cleanedStr } from "@/utils/index";
import CategorySelect from "../../components/stockSelect.vue";
import BrandSelect from "../../components/brandSelect.vue";
import { Toast } from "vant";
import UploadImage from "../../components/uploadImage.vue";
import * as api from "../../service/api/index";

export default {
  name: "index",
  components: {
    CategorySelect,
    BrandSelect,
    UploadImage,
  },
  data() {
    return {
      brandSelectVisible: false,
      categorySelectVisible: false,
      id: this.$route.params.id,
      showPicker: false,
      statusList: [
        { text: "启用", value: "1" },
        { text: "停用", value: "0" },
      ],
      formData: null,
      form: {
        id: "",
        name: "",
        retailPrice: "",
        categoryName: "",
        unit: "",
        externalCode: "",
      },
    };
  },
  watch: {
    "form.name": {
      handler(newVal, oldVal) {
        this.form.shortName = cleanedStr(getCamelCharsUpperCase(newVal));
      },
    },
  },
  async mounted() {
    console.log("modify-->", this.id);
    const res = await get(this.id);
    this.form = { ...res.data, available: res.data.available ? "1" : "0" };
  },
  methods: {
    imageChange(formData) {
      this.formData = formData;
    },
    async images_upload_handler() {
      const res = await api.uploadImage(this.formData);
      this.form.picture = res.data;
    },
    categorySelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.categorySelectVisible = false;
        return;
      }
      this.categorySelectVisible = false;
      this.form.categoryName = row.name;
      this.form.categoryId = row.id;
    },
    brandSelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.brandSelectVisible = false;
        return;
      }
      this.brandSelectVisible = false;
      this.form.brandName = row.name;
      this.form.brandId = row.id;
    },
    showBrandDialog() {
      this.brandSelectVisible = true;
    },
    showDialog() {
      this.categorySelectVisible = true;
      console.log(this.categorySelectVisible);
    },
    cancel() {
      this.showPicker = false;
    },
    async onSubmit() {
     if(this.formData) await this.images_upload_handler();
      const data = {
        ...this.form,
        available: this.form.available === "1" ? true : false,
      };
      const res = await update(data);
      if (res.code === 200) {
        Toast.success("修改商品成功");
        this.$router.push("/product/category");
      }
    },
  },
};
</script>
<style scoped>
::v-deep .van-field__body {
  height: 100%;
}
</style>
